// Alpine
import Alpine from 'alpinejs'
import persist from '@alpinejs/persist'

// Initialize alpine
Alpine.plugin(persist)

// Add to window
window.Alpine = Alpine;
window.Alpine.start();
